/* Dashboard.module.css */

:root {
    --sidebar-width: 200px; /* Define the sidebar width here */
    --menu-button-width: 70px; /* Width of the menu button */
  }
  .content {
    flex: 1;
    margin-left: var(--sidebar-width);
    overflow-y: auto; 
  }
  .dashboardContainer {
    background-color:  #f8f4fc;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .bannerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Banner takes up the remaining width */
  }
  
  .bannerImage {
    width: 100%;
  }
  
  .mainContent {
    margin: 5%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: auto;
    background-color: 
    #f8f4fc;
    /* border: solid red 5px; */
  }

  @media (max-width: 767px) {
    .dashboardContainer {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      margin-left: 0; /* Adjust margin for small screens */
    }
    .content {
        margin-left: var(--menu-button-width); /* Adjust margin for collapsed sidebar */
      }
    .mainContent {
      margin-top: 2rem; /* Example adjustment */
    }
  }
  