:root {
    --font-size-base: 1rem; /* Base font size */
    --font-size-small: 0.875rem; /* Font size for smaller screens */
    --font-size-large: 1.125rem; /* Font size for larger screens */
  }
  
  .form {
    background-color: #ffffff;
    padding: 2%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjust width as needed */
    max-width: 600px; /* Limit maximum width for larger screens */
    margin: 2% auto; /* Center align and provide margin */
    font-size: var(--font-size-base); /* Use base font size */
  }
  
  .formGroup {
    margin-bottom: 2%;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 1%;
    font-size: 1em; /* Relative to parent font size */
  }
  
  .formGroup input[type="text"],
  .formGroup input[type="file"] {
    width: 100%;
    padding: 0.5%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1em; /* Relative to parent font size */
  }
  
  .radioGroup {
    display: flex;
    gap: 2%;
  }
  
  .radioGroup label {
    display: flex;
    align-items: center;
    font-size: 1em; /* Relative to parent font size */
  }
  
  .radioGroup input[type="radio"] {
    margin-right: 0.5%;
  }
  
  .submitButton {
    background-color: #17a2b8;
    color: #ffffff;
    padding: 1% 2%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em; /* Relative to parent font size */
  }
  
  /* Responsive font sizes */
  @media (max-width: 1024px) {
    :root {
      --font-size-base: var(--font-size-small);
    }
  }
  
  @media (max-width: 480px) {
    :root {
      --font-size-base: var(--font-size-small);
    }
  
    .form {
      padding: 4%; /* Increase padding for smaller screens */
    }
  
    .submitButton {
      padding: 2% 4%; /* Increase padding for smaller screens */
    }
  }
  
  @media (min-width: 1025px) {
    :root {
      --font-size-base: var(--font-size-large);
    }
  }
  