:root {
    --sidebar-width: max(12%, 150px);
    --menu-button-width: 60px; /* Adjusted for a more reasonable size on small screens */
}

.sliderlist_container {
    display: flex;
    background-color: #f8f4fc;
    height: 100vh;
    overflow: hidden; /* Ensure container handles overflow */
    font-size: 1rem; /* Base font size */
    
}
.sidebar {
    width: var(--sidebar-width);
    flex-shrink: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

.content {
    background-color: #f8f4fc;
    margin-left: var(--sidebar-width);
    /* padding: 1rem; */
    flex: 1;
    overflow-y: auto;
}

.sliderlist_bannercontainer {
    width: 100%;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
}

.sliderlist_contentContainer {
    width: 100%;
    background-color: #f8f4fc;
    padding: 1rem;
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.sliderlist_bannerimage {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.sliderlist_slider_image {
    max-width: 100px;
    height: auto;
}

.sliderlist_status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 0.8rem;
}

.sliderlist_enable {
    background-color: green;
    color: white;
}

.sliderlist_disable {
    background-color: red;
    color: white;
}

.sliderlist_action_button {
    padding: 0.5rem 1rem;
    background-color: orange;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em; /* Responsive font size */
}

.sliderlist_add_slider {
    padding: 0.5rem 1rem;
    background-color: green;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em; /* Responsive font size */
}

.sliderlist_add_slider_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}


/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.pagination_button {
    padding: 0.5rem 1rem;
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin: 0 0.5rem;
}

.pagination_button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.page_info {
    font-size: 1em;
    margin: 0 1rem;
}


@media (max-width: 1024px) {
    .sliderlist_container {
        font-size: 0.9rem; /* Adjust font size for smaller screens */
    }

    .sliderlist_action_button,
    .sliderlist_add_slider {
        font-size: 0.9em; /* Adjust button font size for smaller screens */
    }
}

@media (max-width: 767px) {
    .sidebar {
        width: var(--menu-button-width); /* Adjust sidebar width for small screens */
    }

    .content {
        margin-left: var(--menu-button-width); /* Adjust content margin for small screens */
    }

    .sliderlist_container {
        flex-direction: column;
        font-size: 0.8rem; /* Adjust font size for smaller screens */
    }

    .sidebar {
        width: 100%;
        position: static;
        overflow-y: auto;
        width: var(--menu-button-width);
    }

    .content {
        margin-left: 0;
        /* padding: 1rem; */
        margin-left: var(--menu-button-width);
    }

    .sliderlist_contentContainer {
        margin-top: 1rem;
    }

    .sliderlist_action_button,
    .sliderlist_add_slider {
        font-size: 0.8em; /* Adjust button font size for smaller screens */
    }

   
}

@media (max-width: 480px) {
    .sliderlist_container {
        font-size: 0.7rem; /* Adjust font size for even smaller screens */
    }

    .sliderlist_action_button,
    .sliderlist_add_slider {
        font-size: 0.7em; /* Adjust button font size for even smaller screens */
    }
}
