:root {
    --sidebar-width: 15%; /* Define the sidebar width here */
    --menu-button-width: 50px; /* Width of the menu button */
  }
  
  .container {
    display: flex;
    background-color: #f8f4fc;
    height: 100vh; /* Full height for the container */
  }
  
  .sidebar {
    width: var(--sidebar-width);
    position: fixed; /* Fix sidebar position */
    top: 0;
    bottom: 0;
    overflow-y: auto; /* Allow sidebar to scroll */
    background-color: #f8f4fc; /* Ensure consistent background color */
    z-index: 1000; /* Ensure sidebar stays above other content */
  }
  
  .content {
    flex-grow: 1;
    margin-left: var(--sidebar-width); /* Account for fixed sidebar */
    overflow-y: auto; /* Ensure content area is scrollable */
 
  }
  
  .bannerContainer {
    width: 100%;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
  }
  
  .bannerImage {
    width: 100%;
  }
  
  .contentContainer {
    width: 100%;
  }
  
  .header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .table {
    padding: 1rem;
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 1rem;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .button {
    background-color: #00aaff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0077cc;
  }

  /* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.pagination_button {
    padding: 0.5rem 1rem;
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin: 0 0.5rem;
}

.pagination_button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.page_info {
    font-size: 1em;
    margin: 0 1rem;
}

  
  @media (max-width: 767px) {
    .container {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%; /* Full width on small screens */
      position: static; /* Reset position for small screens */
      overflow-y: auto; /* Allow sidebar to scroll on small screens */
    }
  
    .content {
        margin-left: 0;
        margin-left: var(--menu-button-width);
    }
    .bannerImage {
      height: auto;
    }
  
    .table th, .table td {
      font-size: 0.8rem;
      padding: 5px;
    }
  
    .header {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }
  