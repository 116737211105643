.headerContainer{
    background-color: #fff;
}
.headerNavbar{
    padding: 0;
    /* border-bottom: 1px solid black; */
}
.headerNavbar a>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.headerNavbar .navbar-nav .nav-link{
    font-weight: 500;
    color: #37393F;
    
}
.headerNavbar .navbar-nav li{
    padding: .5rem .5rem;

}
.headerNavbar .navbar-nav li:last-child{
    padding: .5rem .5rem;
    background-color: #33B1E9;
    border-radius: 5px;
}
.headerNavbar .navbar-nav li:last-child .nav-link{
    color: white !important;
}
/* Mobile Phones (Portrait) */
@media only screen and (max-width: 480px) {
    .headerNavbar .navbar-brand{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.headerNavbar .navbar-brand img{
    width: 150px;
    margin-left: 1rem;
}
  }
  

@media only screen and (min-width: 480px) and (max-width: 767px) {
.headerNavbar .navbar-brand{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.headerNavbar .navbar-brand img{
    width: 150px;
    margin-left: 1rem;
}
  }

  /* Tablets (Portrait) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    /* Styles for tablets in portrait orientation */
    .headerNavbar .navbar-brand{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .headerNavbar .navbar-brand img{
        width: 200px;
        margin-left: 1rem;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    .headerNavbar .navbar-brand img{
        width: 250px;
        margin-left: 1rem;
    }
    .headerNavbar .navbar-brand{
        margin-right: 0 !important;
        margin-left: .5rem !important;
    }
    .headerNavbar .navbar-nav{
        margin-right: 0 !important;
        /* margin-left: 0 !important; */
        margin-bottom: .5rem;
        margin-top: .5rem;

    }
    .headerNavbar .navbar-nav .nav-link{
       padding: 0;        
    }
    .headerNavbar .navbar-nav li:last-child{
        margin-right: .5rem;
    }
}
  
  /* Small Laptops and Desktops */
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .headerNavbar .navbar-brand img{
        width: 250px;
        margin-left: 1rem;
    }
    .headerNavbar .navbar-brand{
        margin-right: 0 !important;
        margin-left: .5rem !important;
    }
    .headerNavbar .navbar-nav{
        margin-right: 0 !important;
        /* margin-left: 0 !important; */
        margin-bottom: .5rem;
        margin-top: .5rem;

    }
    .headerNavbar .navbar-nav .nav-link{
       padding: 0;        
    }
    .headerNavbar .navbar-nav li:last-child{
        margin-right: .5rem;
    }

}


@media only screen and (min-width: 1440px) {
    .headerNavbar .navbar-nav .nav-link{
     font-size: 1.2rem; 
       
    }
    .headerNavbar .navbar-nav{
        margin: 1rem 0;
    }
  }