.container {
    padding: 2%;
    background-color: #f8f4fc;
    font-family: Arial, sans-serif;
    color: #333;
    font-size: 1rem; /* Base font size */
  }
  
  .performance {
    margin-bottom: 2%;
  }
  
  .performance h2 {
    font-size: 1.5rem;
    margin-bottom: 1%;
  }
  
  .numbers {
    display: flex;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    gap: 2%;
  }
  
  .numberBox {
    background-color: #fff;
    border-radius: 8px;
    padding: 0.5% 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    width: calc(50% - 2%); /* Two items per row on larger screens */
    margin-bottom: 2%;
    font-size: 0.9rem; /* Responsive font size */
  }
  
  .numberText {
    display: flex;
    flex-direction: column;
  }
  
  .numberText p {
    font-size: 0.9rem;
    color: #555;
    font-weight: 700;
    margin: 0;
  }
  
  .numberText h3 {
    font-size: 1rem;
    color: #007bff;
    font-weight: 700;
    margin: 0;
  }
  
  .sideIcon {
    height: 60%; /* Adjust this for a default size */
    max-height: 60px; /* Optional: Set a maximum height for larger screens */
    max-width: 60px; /* Optional: Set a maximum width for larger screens */
  }
  
  .analytics {
    background-color: #f9f9fb;
    border-radius: 8px;
    padding: 2%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2%;
  }
  
  .analytics h3 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  
  .chartAndTargets {
    display: flex;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    gap: 2%;
    margin-bottom: 2%;
  }
  
  .chartContainer {
    flex: 2;
    width: 100%; /* Full width on smaller screens */
  }
  
  .chartWrapper {
    background-color: white;
    height: 300px; /* Adjust height as needed */
    width: 100%; /* Full width on smaller screens */
    border-radius: 5px;
    padding: 2%;
    box-sizing: border-box;
  }
  
  .statistics {
    display: flex;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    gap: 20px;
    flex: 1;
  }
  
  .statBox {
    background-color: white;
    border-radius: 8px;
    padding: 2%;
    text-align: center;
    gap: 3%;
    width: calc(50% - 2%); /* Two items per row on larger screens */
    margin-bottom: 2%;
  }
  
  .statBoxHeading {
    font-weight: bolder;
  }
  
  .progressBar {
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    height: 10px;
  }
  
  .progress {
    background-color: #007bff;
    height: 100%;
  }
  
  .icons {
    display: flex;
    flex-wrap: wrap; /* Wrap items on smaller screens */
    gap: 2%;
    width: 100%;
  }
  
  .iconBox {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 2%;
    flex: 1 1 calc(33.33% - 2%); /* Three items per row on larger screens */
    text-align: center;
    cursor: pointer;
    margin-bottom: 2%;
  }
  
 
  
  .sideIcon{
    height: 500px;
    width: 500px;
  }
  .iconBox:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 1024px) {
    .numberBox {
      width: calc(50% - 2%);
    }
    
    .chartWrapper {
      height: 250px; /* Adjust height as needed */
    }
  }
  
  @media (max-width: 767px) {
    .container {
      font-size: 0.9rem; /* Responsive font size */
    }
    
    .numbers {
      flex-direction: column; /* Switch to column layout on smaller screens */
    }
  
    .numberBox {
      width: 100%; /* Full width for number boxes on smaller screens */
      

    }
  
    .chartAndTargets {
      flex-direction: column; /* Stack items vertically on smaller screens */
    }
  
    .statBox {
      width: 100%; /* Full width for stat boxes on smaller screens */
    }
  
    .icons {
      justify-content: center; /* Center align icons on smaller screens */
    }
  
    .iconBox {
      flex: 1 1 calc(50% - 2%); /* Two items per row for icons on smaller screens */
    }
    
    .chartWrapper {
      height: 200px; /* Adjust height as needed */
    }
  
    .sideIcon {
      height: 60%; /* Decrease height on smaller screens */
      max-height: 60px; /* Max height for smaller screens */
      max-width: 60px; /* Max width for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .container {
      font-size: 0.8rem; /* Responsive font size */
    }
    
    .iconBox {
      flex: 1 1 calc(100% - 2%); /* Single column layout for icons on even smaller screens */
    }
    .sideIcon {
        height: 60%; /* Decrease height on smaller screens */
        max-height: 40px; /* Max height for smaller screens */
        max-width: 40px; /* Max width for smaller screens */
      }

      .chartWrapper {
        height: 150px; /* Adjust height as needed */
      }
      .numberBox{
        padding: 2%;
        margin-bottom: 5% !important;
      }
      .numberText p{
        font-size: 0.8rem;
      }
      .numberText h3{
        font-size: 0.7rem;
      }
  }
  