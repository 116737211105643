.fullScreenBackground {
    background-color: #d1e8ff; /* Slightly darker blue background */
    min-height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .policyContainer {
    font-family: Arial, sans-serif;
    padding: 2rem;
    color: #003366; /* Dark Blue */
    background-color: #f4f8ff; /* Light Blue Background */
    border: 1px solid #e0e7ff; /* Light Blue Border */
    border-radius: 0.5rem;
    max-width: 50rem;
    width: 100%;
  }
  
  .policyHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .policyImage {
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
  }
  
  .policyTitle {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .policyContent {
    line-height: 1.6;
  }
  
  .policySection {
    margin-bottom: 2rem;
  }
  
  .policySection h2 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #002244; /* Darker Blue */
  }
  
  .policySection p,
  .policySection ul {
    margin: 1rem 0;
  }
  
  .policySection ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 767px) {
    .policyContainer {
      padding: 1.5rem;
      border-radius: 0.4rem;
      margin: 1.5rem auto;
    }
  
    .policyImage {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1.5rem;
    }
  
    .policyTitle {
      font-size: 1.3rem;
    }
  
    .policySection {
      margin-bottom: 1.5rem;
    }
  
    .policySection h2 {
      font-size: 1.1rem;
    }
  
    .policySection p,
    .policySection ul {
      margin: 0.8rem 0;
    }
  
    .policySection ul {
      padding-left: 1.5rem;
    }
  }
  