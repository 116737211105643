:root {
    --sidebar-width: 15%; /* Define the sidebar width here */
    --collapsed-sidebar-width: 0; /* Width when sidebar is collapsed */
}

.sidebar {
    display: flex;
    flex-direction: column;
    width: var(--sidebar-width);
    background-color: #f8f9fa;
    height: 100vh;
    position: fixed;
    z-index: 1000; 
    top: 0;
    left: 0;
    transition: width 0.3s ease;
}

.sidebar.collapsed {
    width: var(--collapsed-sidebar-width);
}

.menuButton {
    display: none;
    position: fixed;
    top: 1rem;
    left: 0.5rem;
    z-index: 1100;
    width: 10px; /* Decrease the width */
    height: 10px; /* Decrease the height */
    cursor: pointer;
}

.user {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.user svg {
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
}

.userInfo {
    flex-grow: 1;
    transition: margin-top 0.3s ease; /* Add transition for smooth animation */
}

.username {
    margin: 0;
    font-weight: bold;
    font-size: 0.9rem;
    color: black;
}

.userTitle {
    margin: 0;
    font-size: 0.8rem;
    color: #6c757d;
}

.arrow {
    margin-left: auto;
}

.nav {
    flex-grow: 1;
}

.nav ul {
    list-style: none;
    padding: 0;
}

.navItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.navItem img.icon {
    margin-right: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
}

.navItem:hover img.icon, 
.navItem:hover {
    color: #00aaff;
    fill: #00aaff;
}

.navItem:hover img.icon {
    filter: brightness(0) saturate(100%) invert(48%) sepia(87%) saturate(602%) hue-rotate(166deg) brightness(97%) contrast(102%);
}

.settings {
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.settings p {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}

.settings img.icon {
    margin-right: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
}

.settings p:hover, 
.settings p:hover img.icon {
    color: #00aaff;
    fill: #00aaff;
}

.settings p:hover img.icon {
    filter: brightness(0) saturate(100%) invert(48%) sepia(87%) saturate(602%) hue-rotate(166deg) brightness(97%) contrast(102%);
}

@media (max-width: 767px) {
    :root {
        --sidebar-width: 200px !important;
    }

    .sidebar {
        width: var(--sidebar-width);
        position: absolute; /* Make the sidebar absolute on small screens */
    }

    .menuButton {
        display: block; /* Show the menu button */
    }

    .content {
        margin-left: 0; /* Remove margin when sidebar is collapsed */
    }

    .sidebar.collapsed {
        width: var(--collapsed-sidebar-width);
        left: -250px; /* Move sidebar out of view */
    }

    .sidebar.collapsed + .menuButton {
        left: 1rem; /* Keep the menu button visible */
    }

    .sidebar.collapsed + .menuButton img {
        filter: brightness(0) saturate(100%) invert(48%) sepia(87%) saturate(602%) hue-rotate(166deg) brightness(97%) contrast(102%);
    }

    .user {
        margin-top: 40%;
    }
}
