/* DetailedProduct.css */

/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Base styles */
.detailedProductContainer {
  font-family: "Poppins", sans-serif;
}

.detailedProductContainer h1 {
  font-size: 2rem;
  margin-top: 3rem;
}

.bannerImage {
  width: 100%;
}

.proDescription {
  font-size: 1rem;
  text-align: justify; /* Justify all lines */
  text-align-last: left; /* Align last line to the left */
}

.ProjectText h6 {
  font-size: 1rem !important;
}

.ProjectText h5 {
  font-size: 1.5rem !important;
}

.imgDiv {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.squareBoxes {
  width: 10%;
  height: 10%;
  object-fit: cover;
}

.liveProjectContainer {
  margin-top: 2rem;
}

.liveProjectContainer h5 {
  font-weight: 800;
}

.liveProjectSection {
  display: flex;
  margin-top: 2rem;
}

.liveProjectSectionImg {
    /* margin-top: 3rem; */
    /* border: solid red 5px; */
    width: 50%;
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    margin-bottom: 2rem;
  }
  
  .liveProjectSectionImg img {
    width: 80%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
  }
  

.liveProjectSectionContent {
  width: 60% !important;
  margin-left: 2rem; /* Adjust margin between image and text */
}

.liveProjectSectionContent div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.liveProjectSectionContent div button {
  background-color: #33b1e9;
  color: white;
}

.Heading {
  font-size: 1.5rem !important;
  font-weight: 700;
}

.projectDescription {
  font-size: 1rem !important;
}

.btn {
  display: inline-block;
  background-color: #33b1e9;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  cursor: pointer;
  font-size: 0.8rem !important;
  text-decoration: none;
}

.btn a {
  color: white;
  text-decoration: none;
}

/* Media Queries */

  
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .liveProjectSection {
      flex-direction: column;
    }
    .Heading {
        font-size: 1.5rem !important;
      }
      
      .projectDescription {
        font-size: 0.8rem !important;
      }
      
  
    .liveProjectSectionImg,
    .liveProjectSectionContent {
      width: 100% !important;
    }
  
    .liveProjectSectionContent {
      margin-left: 0; /* Remove the left margin */
    }
  
    .liveProjectSectionImg {
      display: flex;
      justify-content: center; /* Center the image horizontally */
      align-items: center; /* Center the image vertically */
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  
    .liveProjectSectionImg img {
      width: 80%;
      height: auto;
      object-fit: cover;
      border-radius: 15px;
    }
  }
  
  
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .liveProjectSectionContent div {
        gap: 0.5rem;
      }
      .liveProjectSectionImg img{
        display: flex;
        justify-content: center;
        width: 100% !important; /* Ensure full width */
      }
      Heading {
        font-size: 1.5rem !important;
      }
      
      .projectDescription {
        font-size: 0.8rem !important;
      }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .liveProjectSectionContent div {
    gap: 1rem;
  }
  Heading {
    font-size: 1.5rem !important;
  }
  
  .projectDescription {
    font-size: 0.9rem !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .liveProjectSectionContent div {
    gap: 1rem;
  }
  Heading {
    font-size: 1.5rem !important;
  }
  
  .projectDescription {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2000px) {
  .liveProjectSectionImg {
    width: 60%;
    margin-right: 2rem;
  }
  .liveProjectSectionContent {
    width: 40%;
    margin-left: 2rem;
  }
  Heading {
    font-size: 1.5rem !important;
  }
  
  .projectDescription {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 2001px) {
  .liveProjectSectionImg {
    width: 60%;
    margin-right: 2rem;
  }
  .liveProjectSectionContent {
    width: 40%;
    margin-left: 2rem;
  }
  Heading {
    font-size: 1.5rem !important;
  }
  
  .projectDescription {
    font-size: 1rem !important;
  }
}
