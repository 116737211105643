.productSection {
    /* border: solid red 5px; */
    width: 90%;
    margin: 1rem auto 0; /* Adjusted margin-top to reduce gap */
    padding-left: 5rem;
    padding-right: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .productBG {
    /* border: solid red 5px; */
    width: 100%;
    margin-bottom: 3%; /* Adjusted margin-bottom to reduce gap */
  }
  
  .productSectionA {
    display: flex;
    font-family: "Poppins", sans-serif;
    /* margin: 5rem 0; */
  }
  
  .productSectionAImg {
    width: 100%; /* Ensures the image takes up full width of its parent */
  }
  
  .productSectionAImg img {
    max-width: 100%; /* Ensures the image does not exceed its original size */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the full image is visible without cropping */
    display: block; /* Ensures the image behaves as a block element */
    margin: 1rem auto 2rem auto; /* Center the image horizontally and add bottom margin */
  }
  
  .productSectionAContent {
    width: 100%; /* Ensures the content takes up full width of its parent */
  }
  
  .productSectionAContent h2 {
    font-weight: 700;
    font-size: 2rem !important;
    margin-bottom: 3rem;
  }
  
  .itemPara {
    padding-right: 5%;
    width: 100%; /* Ensures the paragraph takes up full width of its parent */
    word-spacing: 5px;
    line-height: 1.5;
    color: #6c757d;
    font-size: 1rem !important;
    text-align: justify; /* Justify all lines */
    text-align-last: left; /* Align last line to the left */
  }
  
  
  .ReadMore {
    font-size: 1rem !important;
    background-color: #33b1e9;
    color: white !important;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    padding: 0.5rem 0.8rem;
    margin-bottom: 5%;
  }
  
  /* Mobile Phones (Portrait) */
  @media only screen and (max-width: 550px) {
    .productSection {
        padding: 2rem; /* Decreased padding */
      }
    
      .productBG {
        margin-bottom: 1rem; /* Adjusted margin-bottom */
      }
    
      .productSectionA {
        flex-direction: column;
        padding: 0;
        margin: 0;
      }
    
      .productSectionAImg img {
        max-width: 100% !important; /* Ensure the image does not exceed its original size */
        max-height: 100% !important; /* Ensure the image does not exceed its original size */
        width: 90% !important; /* Allow the image to expand to its natural width */
        height: auto !important; /* Maintain aspect ratio */
        object-fit: contain; /* Ensure the full image is visible without cropping */
        display: block; /* Ensures the image behaves as a block element */
        margin: 0 auto 2rem auto; /* Center the image horizontally and add bottom margin */
      }
      .productSectionAImg img {
          object-fit: contain; /* Ensure the full image is visible without cropping */
          border-radius: 25px !important;
        }
    
  
    
      .productSectionAContent {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    
      .productSectionAContent h2 {
        font-size: 1.3rem !important; /* Decreased font size */
        margin-bottom: 2rem; /* Adjusted margin bottom */
      }
    
      .productSectionAContent p {
        font-size: 0.7rem !important; /* Decreased font size */
      }
    
      .itemPara {
        font-size: 0.9rem !important; /* Decreased font size */
        margin-bottom: 1rem; /* Adjusted margin bottom */
      }
    
      .ReadMore {
        padding: 0.3rem 0.6rem; /* Decreased padding */
        font-size: 0.8rem !important; /* Decreased font size */
        margin-bottom: 10%;
      }
  }
  @media only screen and (min-width:551px) and (max-width: 991px) {
    .productSection {
        padding: 2rem; /* Decreased padding */
      }
    
      .productBG {
        margin-bottom: 1rem; /* Adjusted margin-bottom */
      }
  
    .productSectionA {
      flex-direction: column;
      padding: 0;
      margin: 0;
    }
  
    .productSectionAImg img {
      max-width: 100% !important; /* Ensure the image does not exceed its original size */
      max-height: 100% !important; /* Ensure the image does not exceed its original size */
      width: 70% !important; /* Allow the image to expand to its natural width */
      height: auto !important; /* Maintain aspect ratio */
      object-fit: contain; /* Ensure the full image is visible without cropping */
      display: block; /* Ensures the image behaves as a block element */
      margin: 0 auto 2rem auto; /* Center the image horizontally and add bottom margin */
    }
    .productSectionAImg img {
        object-fit: contain; /* Ensure the full image is visible without cropping */
        border-radius: 25px !important;
      }
  

  
    .productSectionAContent {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  
    .productSectionAContent h2 {
      font-size: 1.3rem !important; /* Decreased font size */
      margin-bottom: 2rem; /* Adjusted margin bottom */
    }
  
    .productSectionAContent p {
      font-size: 0.7rem !important; /* Decreased font size */
    }
  
    .itemPara {
      font-size: 0.9rem !important; /* Decreased font size */
      margin-bottom: 1rem; /* Adjusted margin bottom */
    }
  
    .ReadMore {
      padding: 0.3rem 0.6rem; /* Decreased padding */
      font-size: 0.8rem !important; /* Decreased font size */
    }
  }
  
  /* Tablets (Landscape) */
  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    .productSection {
        padding: 2rem; /* Decreased padding */
      }
    
      .productBG {
        margin-bottom: 1rem; /* Adjusted margin-bottom */
      }
    .productSectionA {
      padding: 0;
    }
  
    .productSectionAImg {
      width: 50%;
    }
  
    .productSectionAImg img {
        object-fit: contain; /* Ensure the full image is visible without cropping */
        border-radius: 25px !important;
      }
  
    .productSectionAContent {
      width: 50%;
    }
  
    .productSectionAContent h2 {
      margin: 0;
    }
  
    .productSectionAContent div {
      margin: 0 0.5rem;
    }
    .productSectionAContent h2 {
        font-size: 1.5rem !important; /* Decreased font size */
        margin-bottom: 2rem; /* Adjusted margin bottom */
      }
    
      .productSectionAContent p {
        font-size: 0.7rem !important; /* Decreased font size */
      }
    
      .itemPara {
        font-size: 0.9rem !important; /* Decreased font size */
        margin-bottom: 1rem; /* Adjusted margin bottom */
      }
    
      .ReadMore {
        padding: 0.3rem 0.6rem; /* Decreased padding */
        font-size: 0.8rem !important; /* Decreased font size */
      }
  }
  
  /* Small Laptops and Desktops */
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .productSection {
        padding: 2rem; /* Decreased padding */
      }
    
      .productBG {
        margin-bottom: 1rem; /* Adjusted margin-bottom */
      }
    .productSectionA {
      padding: 0;
    }
  
    .productSectionAImg {
      width: 50%;
    }
  
    .productSectionAImg img {
      object-fit: contain; /* Ensure the full image is visible without cropping */
      border-radius: 25px !important;
    }
    .productSectionAContent {
      width: 50%;
    }
  
    .productSectionAContent h2 {
      margin: 0;
    }
  
    .productSectionAContent div {
      margin: 0 0.5rem;
    }
    .productSectionAContent h2 {
        font-size: 1.5rem !important; /* Decreased font size */
        margin-bottom: 2rem; /* Adjusted margin bottom */
      }
    
      .productSectionAContent p {
        font-size: 0.7rem !important; /* Decreased font size */
      }
    
      .itemPara {
        font-size: 0.9rem !important; /* Decreased font size */
        margin-bottom: 1rem; /* Adjusted margin bottom */
      }
    
      .ReadMore {
        padding: 0.3rem 0.6rem; /* Decreased padding */
        font-size: 0.8rem !important; /* Decreased font size */
      }
  }
  
  /* Medium to Large Desktops */
  @media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .productSection {
        padding: 2rem; /* Decreased padding */
      }
    
      .productBG {
        margin-bottom: 1rem; /* Adjusted margin-bottom */
      }
    .productSectionAImg img {
      object-fit: contain; /* Ensure the full image is visible without cropping */
      border-radius: 25px;
    }
  
    .productSectionA {
      padding: 0;
    }
    .productSectionAContent h2 {
        font-size: 1.7rem !important; /* Decreased font size */
        margin-bottom: 2rem; /* Adjusted margin bottom */
      }
    
      .productSectionAContent p {
        font-size: 0.8rem !important; /* Decreased font size */
      }
    
      .itemPara {
        font-size: 0.9rem !important; /* Decreased font size */
        margin-bottom: 1rem; /* Adjusted margin bottom */
      }
    
      .ReadMore {
        padding: 0.3rem 0.6rem; /* Decreased padding */
        font-size: 0.8rem !important; /* Decreased font size */
      }
  }
  
  /* Large Desktops and TVs */
  @media only screen and (min-width: 1440px) {
    .productSectionA {
      padding: 0;
    }
  
    .productSectionAContent button {
      font-size: 2rem;
    }
  
    .productSectionAContent h2 {
      font-size: 2.2rem;
      font-weight: 700;
    }
  
    .productSectionAContent p {
      font-size: 1.2rem;
    }
  
    .productSectionAImg img {
      object-fit: contain; /* Ensure the full image is visible without cropping */
      border-radius: 25px;
    }
  }
  