:root {
    --sidebar-width: 15%; /* Define the sidebar width here */
    --menu-button-width: 60px; /* Width of the menu button */
  }
  
  .dashboardContainer {
    display: flex;
    height: 100vh;
  }
  
  .content {
    flex: 1;
    margin-left: var(--sidebar-width);
    overflow-y: auto; 
  }
  
  .bannerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* padding: 1rem; */
  }
  
  .bannerImage {
    width: 100%;
  }
  
  .mainContent {
    flex-grow: 1;
    background-color: #f8f4fc;
    padding: 1rem;
  }
  
  @media (max-width: 767px) {
    .content {
      margin-left: var(--menu-button-width); /* Adjust margin for collapsed sidebar */
    }
  }
  