@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-slide > div {
  margin: 10px 10px;
}

@media only screen and (max-width: 480px) {
  .slick-slide > div {
    width: 230px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* 
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .clientImagesContainer {
    padding: 0.5rem !important;
  }
} */

.slick-dots li.slick-active button:before {
  color: orange !important;
}
