.background {
    background-size: cover; /* Ensures the image covers the entire screen */
    background-repeat: no-repeat; /* Ensures the image does not repeat */
    background-position: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loginContainer {
    background: #2f2537;
    padding: 2%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #fff;
    width: 30%;
    height: 40%;
  }
  
  .loginContainer h2 {
    margin-bottom: 5%;
    font-size: 1.5rem; /* Responsive font size */
  }
  
  .loginContainer input {
    width: 100%;
    padding: 2%;
    margin: 2% 0;
    border: none;
    border-radius: 5px;
    font-size: 1rem; /* Responsive font size */
  }
  
  .loginContainer button {
    width: 100%;
    padding: 2%;
    border: none;
    border-radius: 5px;
    background-color: #5A67D8;
    color: white;
    font-size: 1rem; /* Responsive font size */
    cursor: pointer;
  }
  
  .loginContainer button:hover {
    background-color: #4C51BF;
  }
  
  .forgotPassword {
    display: block;
    margin-top: 2%;
    color: #A0AEC0;
    text-decoration: none;
    font-size: 0.875rem; /* Responsive font size */
  }
  
  .forgotPassword:hover {
    color: #CBD5E0;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 1024px) {
    .loginContainer {
      width: 40%;
      height: 35%;
    }
  }
  
  @media (max-width: 768px) {
    .loginContainer {
      width: 60%;
      height: 35%;
      padding: 3%;
    }
  }
  
  @media (max-width: 480px) {
    .loginContainer {
      width: 80%;
      height: 40%;
      padding: 5%;
    }
  
    .loginContainer h2 {
      margin-bottom: 10%;
      font-size: 1.25rem; /* Smaller font size */
    }
  
    .loginContainer input {
      padding: 3%;
      margin: 8px 0;
      font-size: 0.875rem; /* Smaller font size */
    }
  
    .loginContainer button {
      padding: 3%;
      font-size: 0.875rem; /* Smaller font size */
    }
  
    .forgotPassword {
      margin-top: 8px;
      font-size: 0.75rem; /* Smaller font size */
    }
  }
  